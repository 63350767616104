.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(30px + 4vmin);
    color: white;
    width: 100%;
    margin: 0 auto;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Arrow customization */

.slick-prev,
.slick-next {
    color: #fac65d;
    font-size: 24px;
    z-index: 1;
}

.slick-prev:before,
.slick-next:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
    opacity: 1;
    /* Override the default opacity */
}

.slick-prev {
    left: -30px;
}

.slick-prev:before {
    transform: rotate(-135deg);
}

.slick-next {
    right: -15px;
}

.slick-next:before {
    transform: rotate(45deg);
}